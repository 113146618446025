import { useEffect, useState, useCallback, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import cx from 'clsx';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
import { usePreviewData } from 'utils/preview-data-context';
import { useFunnelData } from 'utils/funnel-data-context';
import { useOrderData } from 'utils/order-data-context';
import * as gtm from 'utils/gtm';
import { finishOrder } from 'utils/order';
import fetcher from 'utils/fetcher';
import styles from './UpsellForm.module.css';
import { buildFunnelPath } from 'utils/url';
import {
  OrderPaymentProcessor,
  DEFAULT_PAYPAL_BUTTON_STYLE,
  OrderPaymentMethod,
  CheckoutFinishUrlToken,
  TransactionErrorCode,
  TransactionErrorMessage,
} from 'constant';
import { buildReturnUrl } from 'utils/afterpay';
import { getPaypalOptions, useHandleViewOrderSummary } from 'utils/checkout-payment';
import CheckoutErrorModal from './CheckoutErrorModal';

const propTypes = {
  yesText: PropTypes.string,
  noText: PropTypes.string,
  countryShippingWhitelist: PropTypes.array,
};

const defaultProps = {
  yesText: 'Yes',
  noText: 'No',
  countryShippingWhitelist: null,
};

function UpsellForm({ yesText, noText, countryShippingWhitelist, className }) {
  const router = useRouter();
  const [isDisabled, setDisabled] = useState(false);
  const [shouldShowActivityIndicator, setShouldShowActivityIndicator] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(null);
  const [modalError, setModalError] = useState({});
  const { preview } = usePreviewData();
  const { funnelId, cartItems, cartData, upsellUrl, downsellUrl, defaultPath, apiBaseUrl } =
    useFunnelData();
  const { orderId, authKey, paymentProcessor, paymentMethod, customerData } = useOrderData();

  const stripe = useStripe();
  const createPaymentIntent = useCallback(
    async ({ currentOrderId, currentCartData }) => {
      try {
        const result = await fetcher(`${apiBaseUrl}/orders`, {
          // Needed for CORS request to send cookies
          credentials: 'include',
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orderId: currentOrderId,
            cartData: currentCartData,
          }),
        });
        return result;
      } catch (err) {
        const errorResponse = { error: { statusCode: err.statusCode } };

        const errorCode = Object.values(TransactionErrorCode).find(code =>
          err?.data?.body?.includes(`"code":${code}`)
        );

        if (errorCode) {
          errorResponse.error.message = TransactionErrorMessage[errorCode];
        } else if (err.data?.body?.includes('ModelValidation')) {
          // Handle server model validation errors
          const body = JSON.parse(err.data.body);
          errorResponse.error.message = `Invalid ${Object.keys(body.data)[0]}.`;
        } else {
          errorResponse.error.message = TransactionErrorMessage.OTHER;
        }

        return errorResponse;
      }
    },
    [apiBaseUrl]
  );

  const buildSuccessUrl = useCallback(() => {
    // Logic based on https://app.shortcut.com/uncoil/story/13405/correct-upsell-downsell-flow#activity-13421
    if (upsellUrl) {
      return upsellUrl;
    } else {
      return CheckoutFinishUrlToken;
    }
  }, [upsellUrl]);

  const success = useCallback(() => {
    gtm.onPurchase({
      orderId,
      cartData,
      purchaseType: gtm.PURCHASE_TYPES.UPSELL_OFFER,
      customerData,
      paymentMethod,
    });

    const url = buildSuccessUrl();

    if (url === CheckoutFinishUrlToken) {
      return finishOrder(orderId, authKey, apiBaseUrl, funnelId);
    } else {
      return router.push(url);
    }
  }, [
    orderId,
    authKey,
    router,
    apiBaseUrl,
    funnelId,
    buildSuccessUrl,
    customerData,
    cartData,
    paymentMethod,
  ]);

  const paypalOptions = getPaypalOptions(cartData);

  const handlePayPalOnClick = useCallback(
    async (_, actions) => {
      setShouldShowActivityIndicator(true);
      return actions.resolve();
    },
    // PayPal callback functions only bind once so we can't rely on React handling the binding
    []
  );

  const payPalCreateOrderRef = useRef({
    orderId,
    cartData,
  });
  useEffect(() => {
    payPalCreateOrderRef.current = {
      orderId,
      cartData,
    };
  }, [orderId, cartData]);
  const handlePayPalCreateOrder = useCallback(
    async () => {
      const { paypalOrderId, error } = await createPaymentIntent({
        currentOrderId: payPalCreateOrderRef.current.orderId,
        currentCartData: payPalCreateOrderRef.current.cartData,
        source: 'UpsellForm - handlePayPalCreateOrder',
      });

      if (error) throw new Error(error.message);

      return paypalOrderId;
    },
    // PayPal callback functions only bind once so we can't rely on React handling the binding
    [createPaymentIntent]
  );

  const payPalOnApproveRef = useRef({
    success,
  });
  useEffect(() => {
    payPalOnApproveRef.current = {
      success,
    };
  }, [success]);
  const handlePayPalOnApprove = useCallback(
    async (data, actions) => {
      try {
        await fetcher(`${apiBaseUrl}/capture`, {
          // Needed for CORS request to send cookies
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paypalOrderId: data.orderID,
          }),
        });
      } catch (err) {
        if (err.statusCode === 422) {
          return actions.restart();
        }

        throw new Error('Unable to process payment, please try again.');
      }

      payPalOnApproveRef.current.success();
    },
    // PayPal callback functions only bind once so we can't rely on React handling the binding
    [apiBaseUrl]
  );

  const payPalOnShippingChangeRef = useRef({
    countryShippingWhitelist,
  });
  useEffect(() => {
    payPalOnShippingChangeRef.current = {
      countryShippingWhitelist,
    };
  }, [countryShippingWhitelist]);
  const handlePayPalOnShippingChange = useCallback(
    (data, actions) => {
      if (
        !payPalOnShippingChangeRef.current.countryShippingWhitelist ||
        payPalOnShippingChangeRef.current.countryShippingWhitelist.some(data.shipping.country_code)
      ) {
        return actions.resolve();
      }

      return actions.reject();
    },
    // PayPal callback functions only bind once so we can't rely on React handling the binding
    []
  );

  const handlePayPalOnError = useCallback(
    error => {
      setShouldShowActivityIndicator(false);
      setFormErrorMessage(error.message);
    },
    // PayPal callback functions only bind once so we can't rely on React handling the binding
    []
  );
  const [showAcceptButton, setShowAcceptButton] = useState(false);

  useEffect(() => {
    const isStripe = paymentProcessor === OrderPaymentProcessor.STRIPE;
    setShowAcceptButton(isStripe || (!paymentProcessor && preview));
  }, [paymentProcessor, preview]);

  const handlePayPalOnCancel = useCallback(
    () => {
      setShouldShowActivityIndicator(false);
    },
    // PayPal callback functions only bind once so we can't rely on React handling the binding
    []
  );

  // If the user hit this upsell directly (ie. didn't have an order going), send them
  // to the start of the funnel.
  useEffect(() => {
    if (!preview && !orderId && funnelId && defaultPath)
      router.push(buildFunnelPath(defaultPath, funnelId));

    // Set the page overflow to hidden when progress bar is being shown
    const htmlElement = document.querySelector('html');
    htmlElement.style = shouldShowActivityIndicator ? 'overflow:hidden' : 'overflow:visible';
  }, [orderId, router, defaultPath, funnelId, shouldShowActivityIndicator, preview]);

  // Ping the API to signal funnel activity
  useEffect(() => {
    if (!orderId) return;

    const orderActivity = async () => {
      try {
        await fetcher(`${apiBaseUrl}/activity?orderId=${orderId}`, {
          // Needed for CORS request to send cookies
          credentials: 'include',
          method: 'PUT',
        });
      } catch (err) {
        if (err.statusCode === 404) {
          setDisabled(true);
          setModalError({
            message: 'Order already completed',
            shouldComplete: true,
            orderId,
            authKey,
          });
        }
      }
    };

    orderActivity();
  }, [orderId, apiBaseUrl, authKey]);

  const handleYes = useCallback(async () => {
    setFormErrorMessage(null);
    setDisabled(true);
    setShouldShowActivityIndicator(true);

    if (!stripe) {
      // Stripe.js has not yet loaded.
      setDisabled(false);
      setShouldShowActivityIndicator(false);
      return;
    }

    const fail = error => {
      if (error.statusCode === 404) {
        setModalError({ message: error.message, shouldComplete: true, orderId, authKey });
      } else if (error.type !== 'api_error') {
        // If the confirm call errored with anything other than an "api_error", show a blocking modal
        setDisabled(false);
        setShouldShowActivityIndicator(false);
        setModalError({ message: error.message, shouldComplete: true, orderId, authKey });
      } else {
        setDisabled(false);
        setShouldShowActivityIndicator(false);
        setFormErrorMessage(error.message);
      }
    };

    const { clientSecret, error } = await createPaymentIntent({
      currentOrderId: orderId,
      currentCartData: cartData,
      source: 'UpsellForm - handleYes',
    });

    if (error) return fail(error);

    if (paymentMethod === OrderPaymentMethod.AFTERPAY) {
      // Redirects to afterpay interface
      const result = await stripe.confirmAfterpayClearpayPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: `${customerData.firstName} ${customerData.lastName}`,
            email: customerData.email,
            address: {
              line1: customerData.shippingAddress,
              city: customerData.shippingCity,
              state: customerData.shippingState,
              country: customerData.shippingCountry,
              postal_code: customerData.shippingZip,
            },
          },
        },
        return_url: buildReturnUrl({
          successUrl: buildSuccessUrl(),
          failUrl: CheckoutFinishUrlToken,
          funnelId,
          cartItems,
          isUpsell: true,
        }),
      });
      if (result.error) return fail(result.error);
    } else {
      const result = await stripe.confirmCardPayment(clientSecret);
      if (result.error) return fail(result.error);
      success();
    }
  }, [
    success,
    createPaymentIntent,
    orderId,
    cartItems,
    cartData,
    stripe,
    paymentMethod,
    customerData,
    buildSuccessUrl,
    funnelId,
    authKey,
  ]);

  const handleNo = useCallback(async () => {
    setDisabled(true);
    setShouldShowActivityIndicator(true);

    // Logic based on https://app.shortcut.com/uncoil/story/13405/correct-upsell-downsell-flow#activity-13421
    if (downsellUrl) {
      router.push(downsellUrl);
    } else if (upsellUrl) {
      router.push(upsellUrl);
    } else {
      finishOrder(orderId, authKey, apiBaseUrl, funnelId);
    }
  }, [upsellUrl, downsellUrl, orderId, authKey, router, apiBaseUrl, funnelId]);

  const { handleViewOrderSummary, isViewOrderSummaryDisabled } = useHandleViewOrderSummary({
    setShouldShowActivityIndicator,
    modalError,
    apiBaseUrl,
    funnelId,
  });

  return (
    <>
      {shouldShowActivityIndicator && <Spinner />}
      {showAcceptButton && (
        <button
          onClick={handleYes}
          disabled={isDisabled}
          className={cx(styles.accept_button, className)}
        >
          {yesText}
        </button>
      )}
      {paymentProcessor === OrderPaymentProcessor.PAYPAL && (
        <div className={styles.paypal_wrapper}>
          <PayPalScriptProvider options={paypalOptions}>
            <PayPalButtons
              onClick={handlePayPalOnClick}
              createOrder={handlePayPalCreateOrder}
              onApprove={handlePayPalOnApprove}
              onShippingChange={handlePayPalOnShippingChange}
              onError={handlePayPalOnError}
              onCancel={handlePayPalOnCancel}
              style={DEFAULT_PAYPAL_BUTTON_STYLE}
            />
          </PayPalScriptProvider>
        </div>
      )}
      <button className={styles.reject_button} onClick={handleNo} disabled={isDisabled}>
        {noText}
      </button>
      {formErrorMessage && <p className={styles.invalid_msg}>{formErrorMessage}</p>}
      {modalError?.message && (
        <CheckoutErrorModal
          title="There was a problem and the latest portion of the transaction could not be completed:"
          message={modalError.message}
          handleViewOrderSummary={handleViewOrderSummary}
          isViewOrderSummaryDisabled={isViewOrderSummaryDisabled}
        />
      )}
    </>
  );
}

UpsellForm.propTypes = propTypes;
UpsellForm.defaultProps = defaultProps;

export default UpsellForm;
